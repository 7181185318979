/**
 * Get an array of years
 * @param {number} numberOfYears
 * @return {number[]}
 */
export const getYears = (numberOfYears: number): number[] => {
  const currentYear = new Date().getFullYear();
  const years = [] as number[];
  for (let index = 0; index < numberOfYears; index++) {
    years.push(currentYear - index);
  }
  return years;
};

type DateFormat = "long" | "short";

/**
 * Get an array of months
 * @param {DateFormat} format
 * @return {string[]}
 */
export const getMonths = (format?: DateFormat, maxMonth?: number): string[] => {
  const month_long = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const month_short = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const months = format === "short" ? month_short : month_long;

  return maxMonth !== undefined ? months.slice(0, maxMonth) : months;
};

/**
 * Format an input value in "accounting" format with number of fixed decimal
 * @param {number | string | null | undefined} value
 * @param {number} fixedDecimal (format with number of fixed decimal)
 * @return {string}
 */
export const formatAccountingNumber = (
  value: number | string | null | undefined,
  fixedDecimal?: number,
  skipNegativeBracket?: boolean
): string => {
  if (isNaN(Number(value))) {
    return "";
  }
  if (!value) {
    return value === 0 ? "-" : "";
  }

  const formattedNumber =
    fixedDecimal || fixedDecimal === 0
      ? Number(value).toLocaleString("en-US", {
          maximumFractionDigits: fixedDecimal,
          minimumFractionDigits: fixedDecimal
        })
      : Number(value).toLocaleString("en-US");

  if (!skipNegativeBracket && Number(value) < 0) {
    return `(${formattedNumber.replace("-", "")})`;
  }

  return formattedNumber;
};
