import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import {
  WithLoader,
  DateSelection,
  YearSelection,
  MonthSelection,
  ApplicationIcon,
  useSnackbar,
  LoadingButton
} from "@packages/theme-mui-v5";
import {
  ManualEntry,
  ManualEntryFieldValues,
  useGetManualEntries,
  useQuery,
  useUpdateManualEntries,
  useGetSchemaPermissions,
  ListOfValuesSchema
} from "@packages/service-api";

import ManualEntryDataGrid from "./ManualEntryDataGrid";
import { Box, Button, Typography } from "@mui/material";
import { dayjs } from "@packages/utils";
import EditBulkEntries from "../../../components/EditBulkEntries";

export type View = "1Month-Daily" | "1Day-Hourly";

type Props = {
  schema: ListOfValuesSchema;
};

export type DataType = "string" | "number";

type ErrorSelection = {
  errorSelection?: boolean;
  dataType?: DataType;
};

export type SelectedCells = ManualEntryFieldValues & {
  timeStamp: string;
  dataType: DataType;
};

export const ManualEntryRenderer: React.FC<Props> = (props) => {
  const { date, month, year } = useQuery<{ date: string; month: string; year: string }>();
  const [selectedCells, setSelectedCells] = React.useState<SelectedCells[]>([]);
  const [updates, setUpdates] = React.useState<ManualEntryFieldValues[]>([]);
  const [showModal, setShowModal] = React.useState<boolean>(null);
  const { enqueueSnackbar } = useSnackbar();
  const defaultDate = React.useMemo(() => {
    return !date || date === "null" || date === "undefined"
      ? dayjs().subtract(1, "day").format("YYYY-MM-DD")
      : date;
  }, [date]);

  const { schema } = props;

  const [selectedDate, setSelectedDate] = React.useState<string>(defaultDate);
  const [selectedYear, setSelectedYear] = React.useState<number>(
    Number(year) || Number(dayjs().format("YYYY"))
  );
  const [selectedMonth, setSelectedMonth] = React.useState<number>(
    Number(month) || Number(dayjs().format("MM"))
  );

  const { dataType, errorSelection } = useMemo<ErrorSelection>(() => {
    if (!selectedCells?.length) return { dataType: null, errorSelection: false };
    const number = [];
    const string = [];
    selectedCells.forEach((data) => {
      if (data.dataType === "number") {
        number.push(data);
      } else {
        string.push(data);
      }
    });
    const errorSelection = Boolean(number.length && string.length);
    return {
      errorSelection,
      dataType: errorSelection ? null : number.length ? "number" : "string"
    };
  }, [selectedCells]);

  const { read, write } = useGetSchemaPermissions(schema);

  const view = React.useMemo(() => {
    if (schema?.settings?.length) {
      return schema.settings.find((data) => data.key === "timeRange")?.value as View;
    }
  }, [schema]);

  React.useEffect(() => {
    if (view === "1Month-Daily") {
      const nextUrl = `${window.location.origin}${window.location.pathname}?month=${selectedMonth}&year=${selectedYear}`;
      window.history.replaceState(null, null, nextUrl);
    }
  }, [view, selectedMonth, selectedYear]);

  const selectedDateForManualEntires = React.useMemo(() => {
    if (view === "1Day-Hourly") {
      return selectedDate;
    } else if (view === "1Month-Daily") {
      return `${selectedYear}-${selectedMonth}-01`;
    }
  }, [view, selectedDate, selectedMonth, selectedYear]);

  const { updateManualEntires, loading: updating } = useUpdateManualEntries(
    schema.schemaId,
    selectedDateForManualEntires
  );

  const onSubmit = async (payload: ManualEntry[]) => {
    try {
      await updateManualEntires(payload);
      setUpdates([]);
      setSelectedCells([]);
      enqueueSnackbar(`Value has been updated successfully.`, {
        variant: "success"
      });
    } catch (err) {
      enqueueSnackbar(
        `Unfortunately, We are unable to save your changes. Please try again. - ${err}`,
        {
          variant: "error"
        }
      );
    }
  };
  const getGroupedData = (data) => {
    const grouped = data.reduce(
      (result, item) => ({
        ...result,
        [item["timeStamp"]]: [...(result[item["timeStamp"]] || []), item]
      }),
      {}
    );
    return grouped;
  };
  const handleSubmit = async (value: string) => {
    // restructure data to save number of API calls
    const grouped = getGroupedData(selectedCells);
    const payload: ManualEntry[] = Object.keys(grouped).map((key) => ({
      timeStamp: key,
      fieldValues: grouped[key].map((item) => ({ key: item.key, value }))
    }));
    await onSubmit(payload);
    setShowModal(false);
  };

  const onEditSubmit = async () => {
    // restructure data to save number of API calls
    const grouped = getGroupedData(updates);
    const payload: ManualEntry[] = Object.keys(grouped).map((key) => ({
      timeStamp: key,
      fieldValues: grouped[key].map((item) => ({ key: item.key, value: item.value }))
    }));
    await onSubmit(payload);
  };

  const {
    manualEntires,
    isFetching: gettingEntries,
    refetchManualEntires
  } = useGetManualEntries(schema.schemaId, selectedDateForManualEntires);

  const maxDate = React.useMemo(() => dayjs().format("YYYY-MM-DD"), []);

  const onDateChange = (date: string) => {
    const nextUrl = `${window.location.origin}${window.location.pathname}?date=${date}`;

    window.history.replaceState(null, null, nextUrl);
    setSelectedDate(date);
  };

  const onCancel = () => {
    refetchManualEntires();
    setUpdates([]);
    setSelectedCells([]);
  };

  React.useEffect(() => {
    setUpdates([]);
    setSelectedCells([]);
  }, [manualEntires]);

  return (
    <Box>
      {schema && (
        <>
          <Box
            sx={(theme) => ({
              background: theme.palette.common.white,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            })}
          >
            <Box>
              {view === "1Day-Hourly" && (
                <Box sx={{ maxWidth: 300 }}>
                  <DateSelection
                    minDate="1999-01-01"
                    maxDate={maxDate}
                    defaultValue={selectedDate}
                    onChange={onDateChange}
                  />
                </Box>
              )}
              {view === "1Month-Daily" && (
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <YearSelection defaultValue={selectedYear} onChange={setSelectedYear} />
                  <MonthSelection
                    value={selectedMonth}
                    maxMonth={
                      selectedYear === Number(dayjs().format("YYYY"))
                        ? Number(dayjs().format("MM"))
                        : undefined
                    }
                    defaultValue={selectedMonth}
                    onChange={setSelectedMonth}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {errorSelection ? (
                <Box
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                    display: "flex",
                    alignItems: "center"
                  })}
                >
                  <ApplicationIcon name="warning" />
                  <Typography
                    variant="body1"
                    sx={{ marginX: 1, fontSize: "16px", fontWeight: 500 }}
                  >
                    Invalid selection
                  </Typography>
                </Box>
              ) : (
                !!selectedCells?.length &&
                !updates?.length && (
                  <Box
                    component="span"
                    onClick={() => setShowModal(true)}
                    sx={{
                      fontWeight: "bold",
                      pr: 1,
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                  >{`${selectedCells?.length} selected`}</Box>
                )
              )}

              {!!updates?.length && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={updating}
                    startIcon={<ApplicationIcon name="cancel" />}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    size="small"
                    variant="contained"
                    disabled={updating}
                    loading={updating}
                    startIcon={<ApplicationIcon name="save" />}
                    onClick={onEditSubmit}
                  >
                    {updating ? "Saving" : "Save Changes"}
                  </LoadingButton>
                </>
              )}
            </Box>
          </Box>
          <Box
            className="show-ag-cell-border"
            sx={{
              position: "relative",
              backgroundColor: gettingEntries ? "background.paper" : "inherit",
              minHeight: 300
            }}
          >
            <WithLoader loading={gettingEntries}>
              {view && !gettingEntries && (read || write) && (
                <ManualEntryDataGrid
                  setSelectedCells={setSelectedCells}
                  setUpdates={setUpdates}
                  updates={updates}
                  view={view}
                  read={read}
                  write={write}
                  schema={schema}
                  manualEntires={manualEntires}
                />
              )}
            </WithLoader>
          </Box>
        </>
      )}
      {showModal && (
        <EditBulkEntries
          onClose={() => setShowModal(false)}
          dataType={dataType}
          selectedCells={selectedCells}
          loading={updating}
          submit={handleSubmit}
        />
      )}
      <Outlet />
    </Box>
  );
};
