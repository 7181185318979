import { useMutation, useQueryClient, UseMutationOptions } from "react-query";
import { HTTPError, ManualEntry, ManualEntryListResponse } from "../../../types";
import { manualEntryListQueryKey } from "./query-cache";
import { ManualEntryApi } from "../../../apis/online-log-sheets/manual-entry";

export const useUpdateManualEntries = (sheetIdOrKey: string, startDate: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<ManualEntryListResponse, HTTPError, ManualEntry[]> = {
    onSuccess: (response: ManualEntryListResponse) => {
      queryClient.setQueryData<ManualEntryListResponse | undefined>(
        manualEntryListQueryKey(sheetIdOrKey, startDate),
        (currentState: ManualEntryListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) => {
              const newData = response.data.find((item) => item.timeStamp === state.timeStamp);
              if (!newData) return state;

              const fieldValues = state.fieldValues.map((data) => {
                const newField = newData.fieldValues.find((field) => field.key === data.key);
                return newField ? newField : data;
              });

              newData.fieldValues.forEach((data) => {
                const isExist = state.fieldValues.find((field) => field.key === data.key);
                if (!isExist) {
                  fieldValues.push(data);
                }
              });

              return {
                timeStamp: state.timeStamp,
                fieldValues
              };
            })
          };
        }
      );
    },
    onError: async (err: HTTPError) => {
      let message = "Failed to perform validation, please try again";

      if (err.response?.status) {
        try {
          const data = await err.response.json();
          message = data?.error || data?.message;
        } catch {
          message = (await err.response.text()) || "Undetermined error";
        }
      }

      err.message = message;
    },
    mutationFn: (manualEntires) => ManualEntryApi.Entries.updateEntries(sheetIdOrKey, manualEntires)
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    manualEntires: data,
    isSuccess,
    error: error,
    loading: isLoading,
    updateManualEntires: mutateAsync
  };
};
